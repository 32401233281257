import styles from "./PublicUserProfileHeader.module.css";
import SupportButton from "../community/contacts/SupportButton";
import { generateAvatarContent } from "./functions/generateAvatarContent";
import { UseGetUserByIdDataResponse } from "./hooks/useGetUserByIdData";
import { useQueryClient } from "react-query";

interface PublicUserProfileHeaderProps {
  userData: UseGetUserByIdDataResponse;
}

function PublicUserProfileHeader({ userData }: PublicUserProfileHeaderProps) {
  const queryClient = useQueryClient();

  const updateIsSupportedInUserQuery = async (
    contactId: string,
    isSupported: boolean
  ) => {
    await queryClient.cancelQueries(`getUserByIdData${contactId}`);
    const userQueryData = queryClient.getQueryData<UseGetUserByIdDataResponse>(
      `getUserByIdData${contactId}`
    );

    if (userQueryData) {
      queryClient.setQueryData<UseGetUserByIdDataResponse | undefined>(
        `getUserByIdData${contactId}`,
        (prevData) => {
          if (prevData) {
            return {
              ...prevData,
              User: { ...prevData.User, IsSupported: !isSupported },
            };
          }
          return undefined;
        }
      );
    }
  };

  return (
    <section className={styles.headerSection}>
      <div className={styles.avatarContainer}>
        {generateAvatarContent({ avatarSrc: userData.User.Avatar })}
      </div>
      <div className={styles.infoContainer}>
        {userData.User.Name && (
          <div className={styles.name}>{userData.User.Name}</div>
        )}
        <div>{`@${userData.User.UserName}`}</div>
        {userData.User.Supports && (
          <div className={styles.followStatus}>Follows You</div>
        )}
        <SupportButton
          isSupported={userData.User.IsSupported}
          contactId={userData.User.Id}
          onSuccess={updateIsSupportedInUserQuery}
        />
      </div>
    </section>
  );
}

export default PublicUserProfileHeader;
