import "./NavDiscussions.css";
import { ReactComponent as AllIconActive } from "../components/icons/AllIconActive.svg";
import { ReactComponent as AllIconInactive } from "../components/icons/AllIconInactive.svg";
import { ReactComponent as ArrowBackIcon } from "../components/icons/ArrowBackIcon.svg";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { ActiveRecipientsResponse } from "../customHooks/useGetPostActivities";
import { RecipientState } from "./DiscussionsOverview";
import DiscussionNavAvatar from "./reusableComponents/DiscussionNavAvatar";
import { RecipientType } from "./types/RecipientType";
import { useNewMessageEventContext } from "./context/NewMessageEventContext";
import { NewMessageNotificationType } from "./types/NewMessageNotificationType";
import { CircularProgress } from "@mui/material";

export interface RecipientsDiscussionNavigation
  extends ActiveRecipientsResponse {}

interface NavDisccussionsProps {
  setFocusedDiscussion: React.Dispatch<SetStateAction<RecipientState>>;
  focusedDiscussion: RecipientState;
  setSearchVisible: React.Dispatch<SetStateAction<boolean>>;
  searchVisible: boolean;
  children: ReactElement;
  recipients: RecipientsDiscussionNavigation[] | undefined;
  setRecipients: React.Dispatch<
    SetStateAction<RecipientsDiscussionNavigation[] | undefined>
  >;
  isLoadingRecipients: boolean;
  handleArrowDownClick: () => void;
  storyId: string;
  storyDateId: string;
}

function NavDiscussions({
  setFocusedDiscussion,
  focusedDiscussion,
  searchVisible,
  setSearchVisible,
  children,
  recipients,
  setRecipients,
  isLoadingRecipients,
  handleArrowDownClick,
  storyId,
  storyDateId,
}: NavDisccussionsProps) {
  const avatarRefs = useRef<HTMLDivElement[]>([]);
  const allIconRef = useRef<HTMLDivElement>(null);

  const { subscribe, unsubscribe } = useNewMessageEventContext();

  const handleNewMessage = useCallback(
    (message: NewMessageNotificationType) => {
      const newMessageRecipientId =
        message.recipientType === RecipientType.Group
          ? message.recipientId
          : message.senderId;

      const foundRecipient = recipients?.find(
        (recipient) =>
          recipient.id === newMessageRecipientId &&
          recipient.recipientType === message.recipientType
      );

      const newMessageRecipient: RecipientsDiscussionNavigation = {
        id: newMessageRecipientId,
        recipientType: message.recipientType,
        avatar:
          message.recipientType === RecipientType.Group
            ? message.recipientAvatar
            : message.senderAvatar,
        unreadMessagesCount: foundRecipient
          ? foundRecipient.unreadMessagesCount + 1
          : 1,
        name:
          message.recipientType === RecipientType.User
            ? message.senderName
            : undefined,
        userName:
          message.recipientType === RecipientType.User
            ? message.senderUserName
            : message.recipientId,
      };

      if (!recipients || recipients.length === 0) {
        setRecipients([newMessageRecipient]);
      } else {
        const updatedRecipients = recipients.filter(
          (recipient) =>
            recipient.id !== newMessageRecipient.id &&
            recipient.recipientType !== newMessageRecipient.recipientType
        );
        setRecipients(() => [newMessageRecipient, ...updatedRecipients]);
      }
    },
    [recipients, setRecipients]
  );

  useEffect(() => {
    const filter = {
      storyId: storyId,
      storyDateId: storyDateId,
    };

    subscribe(filter, handleNewMessage);
    return () => {
      unsubscribe(handleNewMessage);
    };
  }, [handleNewMessage, storyId, storyDateId, subscribe, unsubscribe]);

  useEffect(() => {
    const indexToFocus =
      focusedDiscussion.recipientType === RecipientType.Group ||
      focusedDiscussion.recipientType === RecipientType.User
        ? recipients?.findIndex(
            (recipient) =>
              recipient.id === focusedDiscussion.recipientId &&
              recipient.recipientType === focusedDiscussion.recipientType
          )
        : -1;

    if (focusedDiscussion.recipientType === "World") {
      allIconRef.current?.focus();
    }
    if (
      indexToFocus &&
      indexToFocus !== -1 &&
      avatarRefs.current[indexToFocus]
    ) {
      avatarRefs.current[indexToFocus]?.focus();
    }
  }, [focusedDiscussion, recipients]);

  return (
    <div className="navDiscussions__div">
      {searchVisible ? (
        <ArrowBackIcon
          onClick={() => setSearchVisible(false)}
          style={{ marginRight: "15px" }}
        />
      ) : (
        <>
          <div>
            <ArrowBackIcon
              onClick={() => handleArrowDownClick()}
              style={{ marginRight: "15px" }}
            />
          </div>
          <div
            className="navDiscussions__allIcon"
            ref={allIconRef}
            tabIndex={0}
            onClick={() => {
              setFocusedDiscussion({
                recipientId: "World",
                recipientType: "World",
              });
            }}
          >
            {focusedDiscussion.recipientId === "World" &&
            focusedDiscussion.recipientType === "World" ? (
              <AllIconActive />
            ) : (
              <AllIconInactive />
            )}
          </div>
          {/* <AllDiscussionsIcon
              onClick={() => {
                setFocusedDiscussion({
                  recipientId: "World",
                  recipientType: "World",
                });
              }}
              style={{
                fill:
                  focusedDiscussion.recipientId === "World"
                    ? "#0F7D57"
                    : "black",
              }}
            /> */}

          <div className="navDiscussions__peopleContainer">
            {isLoadingRecipients && (
              <div>
                <CircularProgress size={20} />
              </div>
            )}

            {recipients?.map(
              (recipient: RecipientsDiscussionNavigation, index) => (
                <DiscussionNavAvatar
                  isFocusedDiscussion={
                    focusedDiscussion.recipientId === recipient.id &&
                    focusedDiscussion.recipientType === recipient.recipientType
                  }
                  handleClick={() =>
                    setFocusedDiscussion({
                      recipientId: recipient.id,
                      recipientType: recipient.recipientType,
                    })
                  }
                  ref={(avatar) => {
                    if (avatar) {
                      avatarRefs.current[index] = avatar;
                    }
                  }}
                  recipientAvatar={recipient.avatar}
                  recipientId={recipient.id}
                  recipientType={recipient.recipientType}
                  storyId={storyId}
                  storyDateId={storyDateId}
                  unreadMessagesCount={recipient.unreadMessagesCount}
                  recipientName={recipient.name}
                  recipientUserName={recipient.userName}
                  key={recipient.id}
                />
              )
            )}
          </div>
        </>
      )}
      <div className="navDiscussions__search">{children}</div>
    </div>
  );
}

export default NavDiscussions;
