import { useNavigate } from "react-router-dom";
import styles from "./GetPremiumButton.module.css";

interface GetPremiumButtonProps {
  customButtonText?: string;
}

function GetPremiumButton({ customButtonText }: GetPremiumButtonProps) {
  const navigate = useNavigate();
  const handleGetPremium = () => {
    navigate("/Pricing");
  };
  return (
    <button className={`${styles.buttonPremium}`} onClick={handleGetPremium}>
      {customButtonText ?? "Get Premium"}
    </button>
  );
}

export default GetPremiumButton;
