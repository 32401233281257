import styles from "./PublicUserProfileWrapper.module.css";
import { useParams } from "react-router-dom";
import HeaderFeed from "../feed/HeaderFeed";
import {
  UnknownUserIdError,
  useGetUserByIdData,
} from "./hooks/useGetUserByIdData";
import globalStyles from "../components/global.module.css";
import { CircularProgress } from "@mui/material";
import PublicUserProfileHeader from "./PublicUserProfileHeader";
import UserWorldActivities from "./UserWorldActivities";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";

function PublicUserProfileWrapper() {
  const { userId = "" } = useParams();

  const {
    data: userData,
    isLoading: isLoadingUserData,
    isError: isErrorLoadingUserData,
    error: errorUserData,
  } = useGetUserByIdData(userId);

  const { isMobile } = useResponsiveScreen();

  return (
    <div>
      {isMobile && <HeaderFeed />}
      {isLoadingUserData ? (
        <div className={globalStyles.messageInfo}>
          <CircularProgress />
        </div>
      ) : errorUserData instanceof UnknownUserIdError ? (
        <div className={globalStyles.messageInfo}>User not found</div>
      ) : isErrorLoadingUserData ? (
        <div className={globalStyles.messageInfo}>
          Something went wrong. Please try again later.
        </div>
      ) : userData && userData.User ? (
        <>
          <div className={styles.headerContainer}>
            <PublicUserProfileHeader userData={userData} />
          </div>
          <UserWorldActivities userId={userId} />
        </>
      ) : null}
    </div>
  );
}

export default PublicUserProfileWrapper;
