import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./apiClient";
import { NewNotificationsContextProvider } from "./notifications/context/newNotificationContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { NewMessageEventContextProvider } from "./discussions/context/NewMessageEventContext";
import { PendingMessagesContextProvider } from "./discussions/context/PendingMessagesContext";
import { injectGoogleAnalytics } from './analytics';
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient();
const GA_ID = process.env.REACT_APP_GA_ID;
injectGoogleAnalytics(GA_ID || '');

Sentry.init({
  dsn: "https://394d046c2c610c7ba0c1ace2c58a89fe@o4508620969803776.ingest.us.sentry.io/4508620973277184",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.paperplanes\.me\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <PendingMessagesContextProvider>
            <NewMessageEventContextProvider>
              <NewNotificationsContextProvider>
                <App />
              </NewNotificationsContextProvider>
            </NewMessageEventContextProvider>
          </PendingMessagesContextProvider>
        </Router>
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
