import { CircularProgress } from "@mui/material";
import "./SizeAdjustableTextArea.css";
import { useRef, ChangeEvent, ReactElement, SetStateAction } from "react";
import { useAutosizeTextArea } from "../../customHooks/useAutosizeTextArea";

interface SizeAdjustableTextAreaProps {
  children: ReactElement;
  value: string;
  setValue: React.Dispatch<SetStateAction<string>>;
  isLoading?: boolean;
}

function SizeAdjustableTextArea({
  children,
  value,
  setValue,
  isLoading,
}: SizeAdjustableTextAreaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea({ textareaRef, value, minTextAreaHeight: 32 });

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className="textareaDiv">
      <textarea
        ref={textareaRef}
        placeholder="Type your comment"
        value={value}
        onChange={handleChange}
        className="comment__input"
      />
      <div>
        {isLoading ? (
          <div className="textArea__CircularProgressDiv">
            <CircularProgress size={15} />
          </div>
        ) : null}
        {children}
      </div>
    </div>
  );
}

export default SizeAdjustableTextArea;
