import "./TypeComment.css";
import { useUserPreferencesData } from "../customHooks/useUserPreferencesData";
import MiniAvatar from "../components/elements/MiniAvatar";
import SizeAdjustableTextArea from "./reusableComponents/SizeAdjustableTextArea";
import { ReactComponent as SendIcon } from "../components/icons/SendIcon.svg";
import { useState } from "react";
import { DiscussionType } from "../types/DiscussionType";
import {
  FreeAccountError,
  usePostComment,
} from "../customHooks/usePostComment";
import { RecipientType } from "./types/RecipientType";
import GetPremiumButton from "../subscriptions/GetPremiumButton";
import { toast } from "react-toastify";
import { errorToastOptions } from "../components/toastOptions";

interface TypeCommentProps {
  storyId: string;
  storyDateId: string;
  discussionType: DiscussionType;
  discussionLoaded: boolean;
  recipientId?: string;
}

function TypeComment({
  storyId,
  storyDateId,
  discussionType,
  discussionLoaded,
  recipientId,
}: TypeCommentProps) {
  const [commentValue, setCommentValue] = useState<string>("");
  const [getPremiumButton, setGetPremiumButton] = useState<boolean>(false);

  const { data: userData } = useUserPreferencesData();

  const { mutate, isLoading: isLoadingPostComment } = usePostComment({
    storyId: storyId,
    storyDateId: storyDateId,
    discussionType: discussionType,
    recipientId: recipientId,
    userAvatar: userData?.Avatar || "",
    senderUserName: userData?.UserName || "",
    senderName: userData?.Name || "",
    setCommentValue: setCommentValue,
  });

  const handlePostComment = (comment: string) => {
    mutate(comment, {
      onError: (error: any) => {
        if (error instanceof FreeAccountError) {
          setGetPremiumButton(true);
        } else {
          toast.error(
            "Something went wrong. We couldn't send your message",
            errorToastOptions
          );
        }
      },
    });
  };

  const isButtonDisabled =
    isLoadingPostComment || commentValue.length === 0 || !discussionLoaded;

  return (
    <>
      {getPremiumButton &&
      recipientId === "World" &&
      discussionType === "World" ? (
        <div className="typeComment__getPremiumContainer">
          <GetPremiumButton />
          <div className="getPremiumContainer__text">and join discussion</div>
        </div>
      ) : (
        <div className="typeComment__div">
          <MiniAvatar
            src={userData?.Avatar ? userData.Avatar : ""}
            style={{ marginRight: "10px" }}
            name={userData?.Name ?? undefined}
            userName={userData?.UserName ?? undefined}
            characterType={RecipientType.User}
            height="32px"
          />
          <SizeAdjustableTextArea
            value={commentValue}
            setValue={setCommentValue}
            isLoading={isLoadingPostComment}
          >
            <button
              onClick={() => handlePostComment(commentValue)}
              disabled={isButtonDisabled}
              className="typeCommnet__sendButton"
            >
              <SendIcon
                fill={isButtonDisabled ? "#81bca8" : "#0F5940"}
                className="typeComment_sendIcon"
              />
            </button>
          </SizeAdjustableTextArea>
        </div>
      )}
    </>
  );
}

export default TypeComment;
