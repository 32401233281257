import { apiClient } from "../../apiClient";
import { ContentResponse, CardTypes } from "../types/ContentResponse";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { HeadlineStoryCardApiType } from "../types/HeadlineStoryCardApiType";
import { SummaryStoryCardApiType } from "../types/SummaryStoryCardApiType";
import { StockCardApiType } from "../types/StockCardApiType";
import { TwittCardApiType } from "../types/TwittCardApiType";
import { CardTypesEnum } from "../types/CardTypesEnum";
import { useIsAuthenticated } from "@azure/msal-react";

export interface FeedResponseData {
  Stories: ContentResponse[];
  LastReadNewsId: string;
  DateId: string;
}

interface PageParam {
  LastReadNewsId?: string;
  DateId?: string;
}

export class ForbiddenTimelineError extends Error {
  constructor(code: string, message: string, status: string) {
    super(message);
    this.name = "ForbiddenTimelineError";
  }
}

export const useFetchInfiniteFeed = () => {

  const isAuthenticated = useIsAuthenticated();
  
  async function fetchContent(
    context: QueryFunctionContext<"content", PageParam>
  ): Promise<FeedResponseData | null> {
    try {
      const { pageParam } = context;
      const response: AxiosResponse<FeedResponseData> = await apiClient.get(
        "/timeline",
        {
          params: {
            lastnewsid: pageParam?.LastReadNewsId,
            dateid: pageParam?.DateId,
            number: 5,
          },
        }
      );
      const data = response.data;
      const content =
        data.Stories &&
        data.Stories.map((content: ContentResponse) => ({
          Categories: content.Categories,
          DateId: content.DateId,
          Language: content.Language,
          Id: content.Id,
          Bookmark: content.Bookmark,
          Favorite: content.Favorite,
          Likes: content.Likes,
          Source: content.Source,
          Cards: content.Cards.map((card: CardTypes) => {
            if (card?.Type === CardTypesEnum.HeadlineStoryCard) {
              return card as HeadlineStoryCardApiType;
            } else if (card?.Type === CardTypesEnum.SummaryStoryCard) {
              return card as SummaryStoryCardApiType;
            } else if (card?.Type === CardTypesEnum.StockCard) {
              return card as StockCardApiType;
            } else if (card?.Type === CardTypesEnum.TwittCard) {
              return card as TwittCardApiType;
            } else {
              return null;
            }
          }).filter((card) => card !== null),
        }));
      return {
        Stories: content,
        LastReadNewsId: data.LastReadNewsId,
        DateId: data.DateId,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status === 403 &&
          error?.response?.data.code === "premium_required"
        ) {
          throw new ForbiddenTimelineError(
            error.response.data.code,
            error.response.data.message,
            error.response.data.status
          );
        }
      }
      console.error("Error fetching content", error);
      throw error;
    }
  }

  return useInfiniteQuery("content", fetchContent, {
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.LastReadNewsId && lastPage.DateId) {
        return {
          LastReadNewsId: lastPage.LastReadNewsId,
          DateId: lastPage.DateId,
        };
      } else {
        return undefined; // No more pages
      }
    },
    enabled: isAuthenticated,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
