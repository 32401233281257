import styles from "./UserWorldActivities.module.css";
import globalStyles from "../components/global.module.css";
import { CircularProgress } from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import { useGetWorldActivitiesForUser } from "./hooks/useGetWorldActivitiesForUser";
import { InView } from "react-intersection-observer";
import CommentActivity from "../discussions/CommentActivity";

interface UserWorldActivitiesProps {
  userId: string;
}

function UserWorldActivities({ userId }: UserWorldActivitiesProps) {
  const [triggeringFetchIds, setTriggeringFetchIds] = useState<string[]>([]);

  const {
    data: worldActivities,
    isLoading: isLoadingWorldActivities,
    isError: isErrorWorldActivities,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetWorldActivitiesForUser(userId);

  const hasActivities = useMemo(
    () =>
      worldActivities?.pages.some((page) => page.data.activities.length > 0),
    [worldActivities]
  );

  const fetchMoreData = (inView: boolean, id: string) => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      !triggeringFetchIds.includes(id)
    ) {
      fetchNextPage();
      setTriggeringFetchIds((prevIds) => [...prevIds, id]);
    }
  };

  if (isLoadingWorldActivities) {
    return (
      <div className={styles.activitiesContainer}>
        <CircularProgress />
      </div>
    );
  }
  if (isErrorWorldActivities) {
    return (
      <div className={styles.activitiesContainer}>
        Something went wrong. Could not load user's activity
      </div>
    );
  }
  if (!hasActivities) {
    return (
      <div className={styles.activitiesContainer}>
        There is no activity to display
      </div>
    );
  }

  return (
    <div>
      {worldActivities?.pages.map((page) =>
        page.data.activities.map((activity, index) => (
          <Fragment key={activity.newestMessage.id + "fragment"}>
            {index % 5 === 2 &&
              !triggeringFetchIds.includes(activity.story.id) && (
                <InView
                  onChange={(inView) =>
                    fetchMoreData(inView, activity.story.id)
                  }
                />
              )}
            <CommentActivity
              imgSrc={activity.story.coverUrl}
              headline={activity.story.headline}
              newestMessage={activity.newestMessage.content}
              key={activity.newestMessage.id}
              storyDateId={activity.story.dateId}
              storyId={activity.story.id}
              newestMessageRecipientId={"World"}
              newestMessageRecipientType={"World"}
            ></CommentActivity>
          </Fragment>
        ))
      )}
      {isFetchingNextPage && (
        <div className={globalStyles.bottomLoader}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
}

export default UserWorldActivities;
