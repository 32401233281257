import { useEffect, useState } from "react";

interface ResponsiveScreen {
  isMobile: boolean;
  isTablet: boolean;
  isLargeScreen: boolean;
}
export const useResponsiveScreen = (): ResponsiveScreen => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 480;
  const isTablet = windowWidth > 480 && windowWidth <= 768;
  const isLargeScreen = windowWidth > 768;
  return { isMobile, isTablet, isLargeScreen };
};

