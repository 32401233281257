import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import {
  GetActivitiesResponse,
  QueryContextProps,
  QueryResponseData,
} from "../../customHooks/useGetDiscussionsActivities";
import { AxiosResponse } from "axios";
import { apiClient } from "../../apiClient";

export const useGetWorldActivitiesForUser = (userId: string) => {
  const fetchActivities = async (
    context: QueryFunctionContext<string, QueryContextProps | undefined>
  ): Promise<QueryResponseData> => {
    const response: AxiosResponse<GetActivitiesResponse> = await apiClient.get(
      `discussions/activities/${userId}`,
      {
        params: {
          notnewerthan: context.pageParam?.timestamp,
        },
      }
    );

    const activities = response.data.activities;
    const notnewerthan =
      activities.length > 0
        ? activities[activities.length - 1].newestMessage.timestamp
        : "";

    return {
      data: response.data,
      notnewerthan,
    };
  };

  return useInfiniteQuery(
    `getWorldActivitiesForUser${userId}`,
    fetchActivities,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.notnewerthan) {
          return {
            timestamp: lastPage.notnewerthan,
          };
        } else {
          return undefined;
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};
