import { useMutation, useQueryClient } from "react-query";
import { apiClient } from "../apiClient";
import { DiscussionType } from "../types/DiscussionType";
import { AxiosError, AxiosResponse } from "axios";
import { SetStateAction } from "react";
import { GetDiscussionResponse, MessageResponseType } from "./useGetDiscussion";

interface usePostCommentProps {
  storyId: string;
  storyDateId: string;
  discussionType: DiscussionType;
  recipientId?: string;
  userAvatar: string;
  senderUserName: string;
  senderName: string;
  setCommentValue: React.Dispatch<SetStateAction<string>>;
}

interface PostCommentResponeType {
  message: {
    id: string;
    storyDateId: string;
    storyId: string;
    senderId: string;
    contentType: "text";
    messageType: number;
    recipientId: string;
    plainMessageContent: string;
    timestamp: string;
    isDeleted: boolean;
    deletedAt: null | string;
  };
}

export class FreeAccountError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "FreeAccountError";
  }
}

export const usePostComment = ({
  storyDateId,
  storyId,
  discussionType,
  recipientId,
  userAvatar,
  senderUserName,
  senderName,
  setCommentValue,
}: usePostCommentProps) => {
  const endpoints: Record<DiscussionType, string> = {
    World: `discussions/${storyDateId}/${storyId}`,
    User: `discussions/${storyDateId}/${storyId}/users/${recipientId}`,
    Group: `discussions/${storyDateId}/${storyId}/groups/${recipientId}`,
  };

  const endpoint = endpoints[discussionType] || "";
  const queryClient = useQueryClient();

  const postComment = async (
    content: string
  ): Promise<PostCommentResponeType> => {
    try {
      const response: AxiosResponse<PostCommentResponeType> =
        await apiClient.post(endpoint, { Content: content });
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status === 403 &&
          error.response?.data.code === "premium_required"
        ) {
          throw new FreeAccountError(
            "This option is disabled for free accounts"
          );
        }
      }
      throw error;
    }
  };

  return useMutation(postComment, {
    onSuccess: async (data) => {
      await queryClient.cancelQueries({
        queryKey: [
          `getDiscussions${discussionType}${storyId}${
            recipientId ? recipientId : "World"
          }`,
        ],
      });

      const newComment: MessageResponseType = {
        id: data.message.id,
        content: data.message.plainMessageContent,
        senderAvatar: userAvatar,
        senderUserName: senderUserName,
        timestamp: new Date().toISOString(),
        isDeleted: data.message.isDeleted,
        senderId: data.message.senderId,
        senderName: senderName,
        contentType: data.message.contentType,
        messageType: data.message.messageType,
        deletedAt: data.message.deletedAt,
        reactionsCount: { "\u2764": 0 },
        currentUserReaction: null,
        isCurrentUserMessage: true,
        isRead: true,
      };

      queryClient.setQueryData<GetDiscussionResponse | undefined>(
        [
          `getDiscussions${discussionType}${storyId}${
            recipientId ? recipientId : "World"
          }`,
        ],
        (oldDiscussionCache) => {
          if (!oldDiscussionCache) {
            return oldDiscussionCache;
          }
          const updatedDiscussion = {
            ...oldDiscussionCache,
            discussion: {
              ...oldDiscussionCache.discussion,
              displayMessages: [
                newComment,
                ...oldDiscussionCache.discussion.displayMessages,
              ],
            },
          };
          setCommentValue("");
          return updatedDiscussion;
        }
      );
    },
  });
};
