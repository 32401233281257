import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";
import HeaderFeed from "../feed/HeaderFeed";
import "./AnimationScreen.css";
import { ReactComponent as LogoIcon } from "./icons/PaperPlanes.svg";

interface AnimationScreenProps {
  loadingText: string | null;
  showTopMenu: boolean;
}

function AnimationScreen(props: AnimationScreenProps) {
  const { isMobile } = useResponsiveScreen();

  return (
    <div className="animation__screen">
      {props.showTopMenu && (
        <div className="animation__topMenu">{isMobile && <HeaderFeed />}</div>
      )}

      <div className="animation__container">
        <div className="animation__logoTop">
          <div>Paper Planes</div>
        </div>
        <div className="animation__text">
          {props.loadingText ? <p data-text={props.loadingText}></p> : null}
        </div>
        <div className="topPlane">
          <LogoIcon className="littlePlane" />
        </div>
        <div className="bottomPlane">
          <LogoIcon className="littlePlane" />
        </div>
      </div>
    </div>
  );
}

export default AnimationScreen;
