import defaultProfilePicture from "../defaultProfilePicture.jpg";

interface GenerateAvatarContentProps {
  avatarSrc?: string;
  name?: string;
  userName?: string;
}

const generateAvatarLetters = (
  name: string | undefined,
  userName: string | undefined
) => {
  if (!!name) {
    const nameArr = name.split(" ");
    return (
      nameArr[0].charAt(0).toUpperCase() +
      (nameArr[1]?.charAt(0).toUpperCase() ??
        nameArr[0].charAt(1).toUpperCase() ??
        "")
    );
  } else if (!!userName) {
    const userNameArr = userName.split(" ");
    return (
      userNameArr[0].charAt(0).toUpperCase() +
      (userNameArr[0].charAt(1).toUpperCase() ?? "")
    );
  }
};

export const generateAvatarContent = ({
  avatarSrc,
  name,
  userName,
}: GenerateAvatarContentProps) => {
  if (avatarSrc) {
    return (
      <img
        src={avatarSrc}
        alt="profilePicture"
        style={{ height: "100%", backgroundSize: "contain" }}
      />
    );
  }

  if (name || userName) {
    return generateAvatarLetters(name, userName);
  }

  return <img src={defaultProfilePicture} alt="profilePicture" />;
};
