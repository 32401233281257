import styles from "./ActivitiesDiscussionsNav.module.css";
import HeaderFeed from "../feed/HeaderFeed";
import NavButton from "../community/NavButton";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";

function ActivitiesDiscussionsNav() {
  const [activeView, setActiveView] = useState<string>("");

  const { isMobile } = useResponsiveScreen();

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const pathParts = pathname.split("/");
    setActiveView(pathParts[2].toLowerCase());
  }, [pathname]);

  return (
    <>
      {isMobile && <HeaderFeed />}
      <div className={styles.container}>
        <Link to="Private">
          <NavButton text="Private" isActive={activeView === "private"} />
        </Link>
        <div className={styles.border}></div>
        <Link to="Public">
          <NavButton text="Public" isActive={activeView === "public"} />
        </Link>
      </div>
      <Outlet />
    </>
  );
}

export default ActivitiesDiscussionsNav;
