import { useQuery } from "react-query";
import { apiClient } from "../../apiClient";
import { UserContactResponseType } from "../../types/UserContactResponseType";
import { AxiosError, AxiosResponse } from "axios";

export interface UseGetUserByIdDataResponse {
  User: UserContactResponseType;
}

export class UnknownUserIdError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnknownUserIdError";
  }
}
export const useGetUserByIdData = (userId: string) => {
  const fetchUserByIdData = async (): Promise<
    UseGetUserByIdDataResponse | undefined
  > => {
    try {
      const response: AxiosResponse<UseGetUserByIdDataResponse> =
        await apiClient.get(`users/${userId}`);
      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        throw new UnknownUserIdError("User not found");
      }

      console.error("Error fetching get user by id data, error: ", error);
      throw error;
    }
  };

  return useQuery(`getUserByIdData${userId}`, fetchUserByIdData);
};
