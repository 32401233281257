import "./ReactionsPanel.css";
import { SetStateAction, useState } from "react";
import { ReactComponent as HeartIcon } from "../components/icons/HeartIcon.svg";
import { ReactComponent as HeartIconLiked } from "../components/icons/HeartIconLiked.svg";
import { ReactComponent as DiscussionsIcon } from "../components/icons/CommentIcon.svg";
import { ReactComponent as ShareIcon } from "../components/icons/ShareIcon.svg";
import { ReactComponent as BookmarkIcon } from "../components/icons/BookmarkIcon.svg";
import { ReactComponent as BookmarkIconFilled } from "../components/icons/BookmarkIconFilled.svg";
import { useSendStoryReaction } from "./hooks/useSendStoryReaction";
import { useToggleBookmark } from "./hooks/useToggleBookmark";
import DialogWindow from "../components/DialogWindow";
import { useCopyToClipboard } from "../customHooks/useCopyToClipboard";
import { useIsAuthenticated } from "@azure/msal-react";
import { useHandleLogin } from "../auth/useHandleLogin";

interface ReactionsPanelProps {
  likesNumber: number;
  favorite: boolean;
  bookmark: boolean;
  id: string;
  dateId: string;
  pageNumber?: number;
  showDiscussions: React.Dispatch<SetStateAction<boolean>>;
}

function ReactionsPanel(props: ReactionsPanelProps) {
  const [shareStoryView, setShareStoryView] = useState<boolean>(false);

  const isAuthenticated = useIsAuthenticated();
  const handleLogin = useHandleLogin();

  const host = window.location.host;
  const linkValue = `${host}/News/${props.dateId}/${props.id}`;

  const { isCopied, copyTextToClipboard } = useCopyToClipboard();

  const { mutate: sendReaction } = useSendStoryReaction({
    storyId: props.id,
    storyDateId: props.dateId,
    isFavorite: props.favorite,
    pageNumber: props.pageNumber,
  });

  const { mutate: toggleBookmark } = useToggleBookmark({
    storyId: props.id,
    storyDateId: props.dateId,
    pageNumber: props.pageNumber,
    isBookmarked: props.bookmark,
  });

  const handleReactionClick = () => {
    if (!isAuthenticated) {
      handleLogin();
    } else sendReaction();
  };

  const handleBookmarkClick = () => {
    if (!isAuthenticated) {
      handleLogin();
    } else toggleBookmark();
  };

  const handleDiscussionClick = () => {
    if (!isAuthenticated) {
      handleLogin();
    } else props.showDiscussions(true);
  };

  return (
    <>
      <div className="reactionsPanel">
        <div
          className="reactionsPanel__countable"
          onClick={handleReactionClick}
        >
          {props.favorite ? <HeartIconLiked /> : <HeartIcon />}
          <span className="reactionsPanel_number">{props.likesNumber}</span>
        </div>

        <div
          className="reactionsPanel__countable"
          onClick={handleDiscussionClick}
        >
          <DiscussionsIcon />
          {/* <span className='reactionsPanel_number'>122</span> */}
        </div>

        <div
          className="reactionsPanel__nonCountable"
          onClick={() => setShareStoryView(true)}
        >
          <ShareIcon />
        </div>

        <div
          className="reactionsPanel__nonCountable"
          onClick={handleBookmarkClick}
        >
          {props.bookmark ? <BookmarkIconFilled /> : <BookmarkIcon />}
        </div>
      </div>
      {shareStoryView && (
        <DialogWindow
          question="Copy link to clipboard"
          options={[
            {
              optionName: isCopied ? "Copied!" : "Copy",
              icon: isCopied ? "done" : "content_copy",
              handleClick: () => copyTextToClipboard(linkValue),
              fontColor: isCopied
                ? "var(--green-avatar)"
                : "var(--blue-avatar)",
            },
            {
              optionName: "Close",
              handleClick: () => setShareStoryView(false),
            },
          ]}
        />
      )}
    </>
  );
}

export default ReactionsPanel;
