import "./Button.css";
import { useHandleLogin } from "./useHandleLogin";

export default function SignInButton() {
  const handleLogin = useHandleLogin();

  return (
    <button className="button_in_out" onClick={handleLogin}>
      Sign in
    </button>
  );
}
