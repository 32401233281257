import styles from "./CommunityNav.module.css";
import { Link, Outlet, useLocation } from "react-router-dom";
import NavButton from "./NavButton";
import { useState, useEffect } from "react";
import HeaderFeed from "../feed/HeaderFeed";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";

function CommunityNav() {
  const [active, setActive] = useState<string>("");

  const { isMobile } = useResponsiveScreen();

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const pathParts = pathname.split("/");
    setActive(pathParts[2]?.toLowerCase());
  }, [pathname]);

  return (
    <>
      {isMobile && <HeaderFeed />}
      <div className={styles.container}>
        <div className={styles.nav}>
          <div className={styles.buttonDiv}>
            <Link to="Followers">
              <NavButton text="Followers" isActive={active === "followers"} />
            </Link>
          </div>
          <div className={`${styles.buttonDiv} ${styles.borders}`}>
            <Link to="Following">
              <NavButton text="Following" isActive={active === "following"} />
            </Link>
          </div>
          <div className={styles.buttonDiv}>
            <Link to="Groups">
              <NavButton text="Groups" isActive={active === "groups"} />
            </Link>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default CommunityNav;
