import styles from "./Menu.module.css";
import globalStyles from "./global.module.css";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../components/icons/HomeIcon.svg";
import { ReactComponent as ContactIcon } from "../components/icons/ContactIcon.svg";
import { ReactComponent as BellIcon } from "../components/icons/BellIcon.svg";
import { ReactComponent as ProfileIcon } from "../components/icons/ProfileIcon.svg";
import { useNewNotificationsContext } from "../notifications/context/newNotificationContext";
import { useIsAuthenticated } from "@azure/msal-react";
import { useHandleLogin } from "../auth/useHandleLogin";
import { ReactComponent as Logo } from "../components/icons/PaperPlanes.svg";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";
import HeaderFeed from "../feed/HeaderFeed";

function Menu() {
  const [active, setActive] = useState<string>("/");

  const isAuthenticated = useIsAuthenticated();
  const handleLogin = useHandleLogin();

  const handleUnauthorizedMenuClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!isAuthenticated) {
      event?.preventDefault();
      handleLogin();
    }
  };
  const { isMobile, isLargeScreen } = useResponsiveScreen();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const pathParts = pathname.split("/");
    setActive(pathParts[1]?.toLowerCase());
  }, [pathname]);

  const { unreadNotificationsCount: unreadNotifications } =
    useNewNotificationsContext();

  return (
    <div className={styles.menuContainer}>
      {!isMobile && (
        <div className={styles.logoContainer}>
          <Logo />
        </div>
      )}

      <div className={styles.menuItemsContainer}>
        <Link
          to="/"
          className={globalStyles.removeLinkDecoration}
          onClick={handleUnauthorizedMenuClick}
        >
          <div
            className={`${styles.menuItem} ${active === "" && styles.active}`}
          >
            <div className={styles.iconBox}>
              <HomeIcon
                style={{
                  fill: active === "" ? "#0F7D57" : "#121212",
                }}
                className={styles.icon}
              />
            </div>
            {isLargeScreen && <div className={styles.menuItemName}>Home</div>}
          </div>
        </Link>
        <Link
          to="/Community"
          className={globalStyles.removeLinkDecoration}
          onClick={handleUnauthorizedMenuClick}
        >
          <div
            className={`${styles.menuItem} ${
              active === "community" && styles.active
            }`}
          >
            <div className={styles.iconBox}>
              <ContactIcon
                style={{
                  fill: active === "community" ? "#0F7D57" : "#121212",
                }}
                className={styles.icon}
              />
            </div>
            {isLargeScreen && (
              <div className={styles.menuItemName}>Community</div>
            )}
          </div>
        </Link>

        <Link
          to="/Notifications"
          className={globalStyles.removeLinkDecoration}
          onClick={handleUnauthorizedMenuClick}
        >
          <div
            className={`${styles.menuItem} ${
              active === "notifications" && styles.active
            }`}
          >
            <div className={styles.iconBoxWithNotification}>
              <div className={styles.iconBox}>
                <BellIcon
                  style={{
                    fill: active === "notifications" ? "#0F7D57" : "#121212",
                  }}
                  className={styles.icon}
                />
              </div>
              {unreadNotifications > 0 ? (
                <div className={styles.notificationDot}>
                  <span>
                    {unreadNotifications < 99 ? unreadNotifications : "99+"}
                  </span>
                </div>
              ) : null}
            </div>
            {isLargeScreen && (
              <div className={styles.menuItemName}>Notifications</div>
            )}
          </div>
        </Link>

        <Link
          to="/MyProfile"
          className={globalStyles.removeLinkDecoration}
          onClick={handleUnauthorizedMenuClick}
        >
          <div
            className={`${styles.menuItem} ${
              active === "myprofile" && styles.active
            }`}
          >
            <div className={styles.iconBox}>
              <ProfileIcon
                style={{
                  fill: active === "myprofile" ? "#0F7D57" : "#121212",
                }}
                className={styles.icon}
              />
            </div>
            {isLargeScreen && (
              <div className={styles.menuItemName}>Profile</div>
            )}
          </div>
        </Link>
      </div>
      {!isMobile && (
        <div>
          <HeaderFeed />
        </div>
      )}
    </div>
  );
}

export default Menu;
