import { SetStateAction } from "react";
import GlobalSearchResultsList from "./GlobalSearchResultsList";
import styles from "./GlobalSearchBigScreen.module.css";

interface GlobalSearchBigScreenProps {
  searchPhrase: string;
  setSearchPhrase: React.Dispatch<SetStateAction<string>>;
  debouncedSearchPhrase: string;
  setSearchActive: React.Dispatch<SetStateAction<boolean>>;
}

function GlobalSearchBigSceen({
  searchPhrase,
  setSearchActive,
  setSearchPhrase,
  debouncedSearchPhrase,
}: GlobalSearchBigScreenProps) {
  return (
    <div className={styles.container}>
      <div className={styles.inputDiv}>
        <input
          className={styles.input}
          placeholder="Search..."
          value={searchPhrase}
          onChange={(e) => setSearchPhrase(e.target.value)}
        />
      </div>
      <div className={styles.searchListContainer}>
        <GlobalSearchResultsList
          searchPhrase={debouncedSearchPhrase}
          setSearchActive={setSearchActive}
        />
      </div>
    </div>
  );
}

export default GlobalSearchBigSceen;
