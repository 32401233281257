import styles from "./LastCard.module.css";
import GetPremiumButton from "../subscriptions/GetPremiumButton";
import { ReactComponent as LogoIcon } from "../components/icons/PaperPlanes.svg";

interface LastCardProps {
  header: string;
  additionalInfo: string;
  showPremiumButton?: boolean;
}

function LastCard({
  header,
  additionalInfo,
  showPremiumButton,
}: LastCardProps) {
  return (
    <div className={styles.lastCard} key={`getPremiumCard`}>
      <h3>{header}</h3>
      <p>{additionalInfo}</p>
      {showPremiumButton && <GetPremiumButton />}
      <div className={styles.logo}>
        <LogoIcon />
      </div>
    </div>
  );
}

export default LastCard;
