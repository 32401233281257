import styles from "./HeaderFeed.module.css";
import globalStyles from "../components/global.module.css";
import { ReactComponent as Logo } from "../components/icons/PaperPlanes.svg";
import { ReactComponent as DiscussionsIcon } from "../components/icons/DiscussionsIcon.svg";
import ExpandableSearchInput from "../components/elements/ExpandableSearchInput";
import { useState, useRef, useEffect } from "react";
import GlobalSearchResultsList from "../components/elements/GlobalSearchResultsList";
import { useDebounce } from "react-use";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { usePendingMessagesContext } from "../discussions/context/PendingMessagesContext";
import { useIsAuthenticated } from "@azure/msal-react";
import { useHandleLogin } from "../auth/useHandleLogin";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";
import { ReactComponent as SearchIcon } from "../components/icons/SearchIcon.svg";
import GlobalSearchBigSceen from "../components/elements/GlobalSearchBigSceen";

function HeaderFeed() {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState("");
  const { isMobile, isLargeScreen } = useResponsiveScreen();

  const { pendingMessages } = usePendingMessagesContext();

  const [path, setPath] = useState<string>("");

  const isAuthenticated = useIsAuthenticated();
  const handleLogin = useHandleLogin();

  const handleActivitiesIconClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!isAuthenticated) {
      event?.preventDefault();
      handleLogin();
    }
  };

  const handleSearchIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isAuthenticated) {
      event?.preventDefault();
      handleLogin();
    } else setSearchActive(!searchActive);
  };

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const pathParts = pathname.split("/");
    setPath(pathParts[1]?.toLowerCase());
  }, [pathname]);

  useDebounce(() => setDebouncedSearchPhrase(searchPhrase), 500, [
    searchPhrase,
  ]);

  const searchRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsideSearchWindow = (e: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(e.target as Node) &&
        resultsRef.current &&
        !resultsRef.current.contains(e.target as Node)
      ) {
        setSearchActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideSearchWindow);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSearchWindow);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <div className={styles.mainContainer}>
          <div className={styles.menuContainer}>
            <Link to="/" className={globalStyles.removeLinkDecoration}>
              <div className={styles.logo}>
                <Logo />
              </div>
            </Link>

            <div className={styles.menuItemsContainer}>
              <div ref={searchRef}>
                <div>
                  <ExpandableSearchInput
                    isSearchActive={searchActive}
                    setSearchActive={setSearchActive}
                    searchPhrase={searchPhrase}
                    setSearchPhrase={setSearchPhrase}
                    iconSize="30px"
                    iconStrokeWidth="1.8"
                  />
                </div>
              </div>
              <Link
                to="/Activities"
                onClick={handleActivitiesIconClick}
                className={globalStyles.removeLinkDecoration}
              >
                <div
                  className={`${styles.menuItem} ${
                    path === "activities" && styles.active
                  }`}
                >
                  <div className={styles.discussionsIconBox}>
                    <DiscussionsIcon
                      className={styles.discussionIcon}
                      fill={`${path === "activities" ? "#0F7D57" : "#121212"}`}
                    />
                    {pendingMessages && (
                      <div className={styles.discussionsNotificationDot}></div>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div ref={resultsRef}>
            {searchActive && (
              <GlobalSearchResultsList
                searchPhrase={debouncedSearchPhrase}
                setSearchActive={setSearchActive}
              />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.menuContainer}>
            <div className={styles.menuItemsContainer}>
              <div ref={searchRef}>
                <div
                  className={styles.menuItem}
                  onClick={handleSearchIconClick}
                >
                  <SearchIcon
                    stroke={searchActive ? "#0F7D57" : "#121212"}
                    className={styles.searchIconBigScreen}
                  />
                  {isLargeScreen && (
                    <div className={styles.menuItemName}>Search</div>
                  )}
                </div>
              </div>
              <Link
                to="/Activities"
                onClick={handleActivitiesIconClick}
                className={globalStyles.removeLinkDecoration}
              >
                <div
                  className={`${styles.menuItem} ${
                    path === "activities" && styles.active
                  }`}
                >
                  <div className={styles.discussionsIconBox}>
                    <DiscussionsIcon
                      className={styles.discussionIcon}
                      fill={`${path === "activities" ? "#0F7D57" : "#121212"}`}
                    />
                    {pendingMessages && (
                      <div className={styles.discussionsNotificationDot}></div>
                    )}
                  </div>
                  {isLargeScreen && (
                    <div className={styles.menuItemName}> Activities</div>
                  )}
                </div>
              </Link>
            </div>
          </div>
          <div ref={resultsRef}>
            {searchActive && (
              <div className={styles.searchPanelBigScreen}>
                <GlobalSearchBigSceen
                  searchPhrase={searchPhrase}
                  setSearchActive={setSearchActive}
                  setSearchPhrase={setSearchPhrase}
                  debouncedSearchPhrase={debouncedSearchPhrase}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default HeaderFeed;
