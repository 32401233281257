import { useEffect } from "react";

interface UseAutosizeTextAreaProps {
  textareaRef: React.RefObject<HTMLTextAreaElement> | null;
  value: string;
  minTextAreaHeight: number;
}
export const useAutosizeTextArea = ({
  textareaRef,
  value,
  minTextAreaHeight,
}: UseAutosizeTextAreaProps) => {
  useEffect(() => {
    const textarea = textareaRef?.current;
    if (textarea) {
      textarea.style.height = "0px";
      textarea.style.height = `${Math.max(
        textarea.scrollHeight,
        minTextAreaHeight
      )}px`;
    }
  }, [value, textareaRef, minTextAreaHeight]);
};
